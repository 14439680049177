import { IDoctosyncUnmatchedPatients, IDBDoctolib } from '../../../types';
import { RoseApi } from './apiTypes';

export type DoctosyncApi = ReturnType<typeof createDoctosyncApi>;

export function createDoctosyncApi(roseApi: RoseApi) {
  return {
    getUnmatchedPatients: async () => roseApi.doctosync.get<IDoctosyncUnmatchedPatients[]>(`/api/unmatchedpatients`),
    addPatid: async (extid: string, patid: string) => roseApi.doctosync.post(`/api/addpatid`, { extid, patid }),
    reset: async (cid: string, hard = false) =>
      roseApi.doctosync.post(`/api/reset`, { hard }, { headers: { 'rose-client-id': cid } }),
    getMeta: async (cid: string) =>
      roseApi.doctosync.get<IDBDoctolib>(`/api/meta`, { headers: { 'rose-client-id': cid } }),
  };
}
