import "core-js/modules/es.array.map.js";
import { defineComponent } from 'vue';
import { german, formatBytes } from '@rose/common-ui';
import { roseDayjs } from '../../../../../../base';
import { settingsImportBotProtocolColumnsDefs, settingsImportCollectorProtocolColumnsDefs } from '@/components/columns/settings/importProtocol';
import { chain, isEmpty } from 'lodash';
export default defineComponent({
  props: {
    importProtocolComplete: {
      type: Object,
      required: true
    },
    tableType: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: [],
      rowData: [],
      protocolInfo: {},
      from: {},
      to: {},
      size: undefined
    };
  },
  computed: {
    duration: function duration() {
      return roseDayjs.duration(this.to.diff(this.from)).asSeconds();
    }
  },
  created: function created() {
    this.gridOptions = {
      rowHeight: 40,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        sortable: true
      },
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };

    if (this.tableType === 'bot') {
      this.columnDefs = settingsImportBotProtocolColumnsDefs();
      this.protocolInfo = this.importProtocolComplete.botProtocol;
    } else {
      this.columnDefs = settingsImportCollectorProtocolColumnsDefs();
      this.protocolInfo = this.importProtocolComplete.collectorProtocol;
    }

    this.rowData = this.getModelInfoArray(this.protocolInfo.modelInfo);
    this.from = roseDayjs(chain(this.protocolInfo.modelInfo).map(function (m) {
      return m.started;
    }).compact().min().value());
    this.to = roseDayjs(chain(this.protocolInfo.modelInfo).map(function (m) {
      return m.ended;
    }).compact().max().value());
    this.size = formatBytes(chain(this.protocolInfo.modelInfo).map(function (m) {
      return m.size;
    }).compact().sum().value());
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('cell clicked:', event);
    },
    getModelInfoArray: function getModelInfoArray(modelInfos) {
      return chain(modelInfos).pickBy(function (modelInfo) {
        return modelInfo.size !== 0 || !isEmpty(modelInfo.patids);
      }).map(function (value, key) {
        if (Object.prototype.hasOwnProperty.call(modelInfos, key)) {
          return Object.assign({
            name: key
          }, modelInfos[key]);
        }
      }).value();
    }
  }
});