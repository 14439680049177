import { dauerFromSecondsFormatter, IRoseAGGridColumn, decimalFilter, binarySizeFormatter } from '@rose/common-ui';
import { roseDayjs } from '../../../../../base';
import { IImportProtocolModelDetails } from '@rose/types';
import { keys, values, sumBy, size } from 'lodash';

export function settingsImportCollectorProtocolColumnsDefs(): IRoseAGGridColumn<IImportProtocolModelDetails>[] {
  return settingsImportProtocolColumnsDefs(true);
}
export function settingsImportBotProtocolColumnsDefs(): IRoseAGGridColumn<IImportProtocolModelDetails>[] {
  return settingsImportProtocolColumnsDefs(true);
}

export function settingsImportProtocolColumnsDefs(withSize: boolean): IRoseAGGridColumn<IImportProtocolModelDetails>[] {
  const cols: IRoseAGGridColumn<IImportProtocolModelDetails>[] = [
    {
      headerName: 'Modell',
      field: 'name',
      resizable: true,
      sortable: true,
      minWidth: 140,
    },
    {
      headerName: '# Patienten',
      valueGetter: params => keys(params.data?.patids).length,
      resizable: true,
      sortable: true,
      minWidth: 80,
      cellStyle: { 'justify-content': 'end' },
      flex: 1,
    },
    {
      headerName: '# Elemente',
      valueGetter: params => sumBy(values(params.data?.patids), p => size(p)) || params.data?.count,
      valueFormatter: params =>
        decimalFilter.filters.decimal(sumBy(values(params.data?.patids), p => size(p)) || params.data?.count || 0),
      resizable: false,
      minWidth: 80,
      cellStyle: { 'justify-content': 'end' },
      flex: 1,
    },
    {
      headerName: 'Zeit',
      valueGetter: params => params.data?.started,
      valueFormatter: params =>
        `${roseDayjs(params.data?.started).format('HH:mm:ss')} - ${roseDayjs(params.data?.ended).format('HH:mm:ss')}`,
      resizable: true,
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: 'Dauer',
      field: 'duration',
      valueFormatter: dauerFromSecondsFormatter,
      resizable: true,
      sort: 'desc',
      minWidth: 80,
      flex: 1,
    },
  ];
  if (withSize) {
    cols.push({
      headerName: 'Größe',
      field: 'size',
      valueFormatter: binarySizeFormatter,
      resizable: true,
      minWidth: 100,
      flex: 1,
    });
  }
  return cols;
}
