/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ProtocolTable.vue?vue&type=template&id=c8bd7d10&scoped=true&lang=pug&"
import script from "./ProtocolTable.vue?vue&type=script&lang=ts&"
export * from "./ProtocolTable.vue?vue&type=script&lang=ts&"
import style0 from "./ProtocolTable.vue?vue&type=style&index=0&id=c8bd7d10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8bd7d10",
  null
  
)

export default component.exports